// code from: https://codepen.io/jyoon93/pen/POoPeM


export const CustomTypingIndicator = () => {
  return (
    <div className="customTypingIndictor__container">
      <span className="customTypingIndictor__dot"></span>
      <span className="customTypingIndictor__dot"></span>
      <span className="customTypingIndictor__dot"></span>
    </div>
  );
};